<template>
  <div class="wrapper-ficha noticia">
    <a @click="back" class="volver"><img src="/img/flecha_salir-azul.svg" alt="" /></a>

    <div class="contenedor-evento" v-if="data">
      <div class="imagen-principal">
        <img :src="data.image.url" :alt="data.title" />
      </div>

      <div class="contenedor-cuerpo">
        <div class="cuerpo-real">
          <h2>{{ data.title }}</h2>
          <h4 class="js-fecha">{{ data.date | date }}</h4>

          <p v-if="data.category.name">
            <strong>Tipo de evento:</strong> {{ data.category.name }}
          </p>
          <p v-if="data.organizer">
            <strong>Organizador:</strong> {{ data.organizer }}
          </p>
          <p v-if="data.address"><strong>Lugar:</strong> {{ data.address }}</p>
          <p v-if="data.schedule">
            <strong>Horario:</strong> {{ data.schedule }}
          </p>

          <p v-if="data.price"><strong>Coste:</strong> {{ data.price }}</p>
          <p v-if="data.type">
            <strong v-if="data.type == 0">General</strong>
            <strong v-if="data.type == 1">Presencial</strong>
            <strong v-if="data.type == 2">Online</strong>
          </p>
          <editorjs :modulos="data.description"></editorjs>

          <div class="row-start" v-if="showinscripcion">
            <button class="boton-azul" @click="todown">Inscribirse</button>
          </div>
        </div>
      </div>
      <div class="formulario-inscripcion" id="formulario" v-if="showinscripcion">
        <h2>Inscríbete</h2>
        <ValidationObserver ref="form" v-slot="{ failed, valid }">
          <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
            <div class="contenedor-input half">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input autocomplete="off" type="text" name="nombre" placeholder="Nombre*" v-model="dataform.name"
                  :class="classes" />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-input half">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input autocomplete="off" type="text" name="apellidos" placeholder="Apellidos*"
                  v-model="dataform.surnames" :class="classes" />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-input half">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input autocomplete="off" type="email" name="mail" placeholder="Email*" v-model="dataform.email"
                  :class="classes" />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-input half">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input autocomplete="off" type="text" name="movil" placeholder="Móvil*" v-model="dataform.phone"
                  :class="classes" />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <!-- <div class="contenedor-input half">
              <ValidationProvider
                rules="required"
                v-slot="{ errors, ariaInput, ariaMsg, classes }"
              >
                <input
                  autocomplete="off"
                  type="text"
                  name="dni"
                  placeholder="DNI*"
                  v-model="dataform.dni"
                  :class="classes"
                />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div> -->

            <div class="contenedor-input half">
              <ValidationProvider rules="numeric|max_value:2" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input autocomplete="off" type="number" name="companions" placeholder="Número de acompañantes"
                  v-model="dataform.companions" :class="classes" />
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-input half" v-if="data.type === 0">
              <ValidationProvider :rules="{ notZero: true }" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <select id="attendance_preference" name="attendance_preference" v-model="dataform.type"
                  :class="classes">
                  <option value=0 disabled>Preferencia de asistencia*</option>
                  <option value="1">Presencial</option>
                  <option value="2">Online</option>
                </select>
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-input">
              <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                <input type="checkbox" id="contact" v-model="dataform.privacy_check" :class="classes" />
                <label for="contact">Acepto y autorizo a que mis datos sean tratados por la
                  Universidad Francisco de Vitoria, con las finalidades
                  indicadas anteriormente y para remitirme, por cualquier medio,
                  incluidos los electrónicos (SMS, Whatsapp, correo electrónico
                  y redes sociales) las comunicaciones correspondientes.*</label>
                <span v-bind="ariaMsg" class="custom-error">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>

            <div class="contenedor-boton">
              <div class="contenedor-boton row-start">
                <button class="boton-azul" :loading="loading">
                  Inscribirse
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="popup" v-if="showpopup">
      <div class="paso" v-if="hasToRegister">
        <span class="close" @click="showpopup = !showpopup">X</span>
        <h2 class="titulo" style="text-align: center; margin-bottom: 15px">
          <strong>¡Atención!</strong>
        </h2>

        <p>
          El email que ha indicado no consta como usuario registrado de Padres
          UFV. Para apuntarse a este evento, pulse a continuación:
        </p>

        <a class="boton-azul" :href="urlAlumni +
          'es/general/encuesta-padres?event_id=' +
          dataform.event_id
          " target="_blank">Registrarme y apuntarme al evento</a>
      </div>
      <div class="paso" v-else>
        <span class="close" @click="showpopup = !showpopup">X</span>
        <h2 class="titulo" style="text-align: center">
          <strong>Enhorabuena</strong>
        </h2>

        <p>Tu inscripción se ha realizado correctamente.</p>

        <p>
          Te mantendremos informado de todas nuestras actividades por correo
          electrónico También puedes seguirnos a través de la
          <a href="https://padres-ufv.es/">web</a> e
          <a target="_blank" href="https://www.instagram.com/ufvfamilias/">instagram.</a>
        </p>

        <p>
          Cualquier consulta puedes escribirnos a
          <a href="mailto:familias@ufv.es"><u>familias@ufv.es.</u></a>
        </p>

        <p>¡Bienvenido a UFV Familias!</p>

        <a @click="showpopup = false" class="boton-azul">Aceptar</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import { extend } from "vee-validate";

extend("notZero", {
  validate: (value) => value !== 0,
  message: "Debes seleccionar una opción válida.",
});
export default {
  name: "evento",
  mounted() {
    this.urlAlumni =
      location.hostname == "familias-ufv.es" || location.hostname == "padres-ufv.es"
        ? "https://alumni-ufv.es/"
        : "https://alumni-ufv-front.dev.doubledot.es/";
    this.setData();
  },
  data: () => ({
    data: "",
    loading: false,
    hasToRegister: false,
    urlAlumni: null,
    dataform: {
      name: "",
      surnames: "",
      email: "",
      phone: "",
      // dni: "",
      privacy_check: "",
      event_id: "",
      attendance_preference: "",
      companions: "",
      type: null,
    },
    showpopup: false,
  }),
  methods: {
    ...mapActions([
      "requestEvento",
      "requestInscripcion",
      "requestCancelInscripcion",
    ]),
    setData() {
      this.requestEvento(this.$route.params.id).then((r) => {
        this.data = r;
        this.dataform.event_id = r.id;
        this.dataform.type = r.type;
      });
    },
    back() {
      router.go(-1);
    },
    validate() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.inscripcion();
        }
      });
    },
    inscripcion() {
      this.loading = true;

      if (!this.dataform.companions) {
        this.dataform.companions = null;
      }

      this.requestInscripcion(this.dataform).then((r) => {
        this.loading = false;
        this.hasToRegister = false;
        if (r == 403) {
          this.hasToRegister = true;
        }
        if (r) {
          this.dataform = {
            name: "",
            surnames: "",
            email: "",
            phone: "",
            // dni: "",
            privacy_check: "",
            event_id: "",
            attendance_preference: "",
            type: this.data.type,
          };
          this.showpopup = true;
          this.dataform.event_id = this.data.id;


        }
        this.$refs.form.reset();
      });
    },
    cancelar() {
      this.loading = true;
      this.requestCancelInscripcion(this.data.id).then(() => {
        this.loading = false;
        this.setData();
      });
    },
    todown() {
      scroll({
        top: document.getElementById("formulario").getBoundingClientRect().top,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapGetters({
      dominios: "getDominios",
    }),
    fechapasada() {
      let pasada = new Date(this.data.limit_cancel_date).getTime();
      let actual = new Date().getTime();

      return actual > pasada;
    },
    fechaactual() {
      let pasada = new Date(this.data.date).getTime();
      let actual = new Date().getTime();

      return actual > pasada;
    },
    showinscripcion() {
      let can = false;
      if (this.data && this.data.open) {
        let date = new Date().setHours(0, 0, 0, 0);
        let close_date = new Date(this.data.date).setHours(0, 0, 0, 0);

        if (date <= close_date) {
          can = true;
        }
      }

      return can;
    },
  },
  watch: {
    $route() {
      this.setData();
    },
  },
};
</script>

<style lang="scss" scoped>
.contenedor-input {
  padding-bottom: 20px;

  select {
    width: 100%;
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  height: 100%;
  max-width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .paso {
    max-width: 500px;
    background: white;
    padding: 20px;
  }

  .close {
    width: 100%;
    display: flex;
    justify-content: end;
    cursor: pointer;
    font-size: 25px;
    font-weight: 700;
  }
}
</style>
